// IMPORTS --------------------------------------------------------------------------------

// Lexend (Font):
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat+Alternates:wght@300;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat+Alternates:wght@700;900&family=Montserrat:wght@200;400;700;900&display=swap');

// Source Code Pro (Font):
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,400;1,700&display=swap');

// Dancing Script
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

// VARIABLES ------------------------------------------------------------------------------

// Fonts
// $text: 'Lexend', sans-serif
// $text: 'Montserrat Alternates', sans-serif
$text: 'Montserrat';
$code: 'Source Code Pro', sans-serif;
$hand: 'Dancing Script', sans-serif;

// Colors
$jcMain: #005af5;
$jcDark-main: #232429;
$jcBlack-main: #191A1D;
$jcGray: #E6E7EB;

$jcGreen: #27FFA9;
$jcRed: #ff3978;
$jcYellow: #FFCC00;

$jcWhite: #eeeeee;
$jcBlack: #1b1b1b;
$jcFullw: #fff;
$jcFullb: #000;

$acoustMain: #ADFF00;
$acoustDark-main: #151A13;
$acoustDark: #418330;

// Sizes
$borderL: 1rem;
$borderS: 0.3rem;
$borderR: 100rem;
$desktopBodyPadding: 15rem;
$phoneBodyPadding: 3rem;
$padding: .5rem 1rem;
$transition: 150ms;

// Breakpoints
$phone: 640px;
$tablet: 1400px;
$desktop: 1920px;
$desktopWQHD: 2560px;
$desktopUHD: 3840px;

// BOILERPLATE ----------------------------------------------------------------------------

::selection {
    background: $jcMain;
    color: $jcWhite; }

*, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $text;
    scroll-behavior: smooth; }

body {
    width: 100%;
    height: 100%;
    background: $jcWhite;
    overflow-x: hidden; }
