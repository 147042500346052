@import "design";

footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem 10rem 2rem 10rem;
  align-items: center;
  p {
    font-weight: bold; }
  a {
    color: $jcMain;
    font-weight: bold; }
  @media screen and (max-width: $tablet) {
    padding: 2rem; }

  footer {
    background-color: $acoustDark-main;
    color: $acoustMain;
    a {
      color: $acoustMain; } } }

#header-impr {
  justify-content: center;
  a {
    color: black;
    text-decoration: none; }
  @media screen and (max-width: $phone) {
    padding: 1.5rem 3rem 1.5rem 3rem;
    .logo {
      .line, .name {
        display: block; } } } }

#impressum {
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translate(-50%);
  line-height: 1.5;
  h1 {
    font-size: 2rem; }
  h2 {
    margin-top: 1.5rem; }
  @media screen and (max-width: $phone) {
    width: 80vw; } }

header {
  width: 100%;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  padding: 2rem 10rem 2rem 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  hr {
    height: 3px;
    border: none;
    background: #e0e1e6;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0; }
  .logo {
    display: flex;
    align-items: center;
    gap: 1rem;
    svg {
      width: 4rem; }
    .line {
      height: 2.15rem;
      width: .2rem;
      background-color: $jcBlack-main; }
    .name {
      font-weight: 900; } }
  .nav {
    .mobile {
      display: none; }
    .nav-list {
      display: flex;
      gap: 4rem;
      list-style: none;
      .nav-item {
        a {
          color: $jcBlack;
          text-decoration: none;
          transition: 100ms;
          font-size: 1.1rem;
          font-weight: bold;
          &:hover {
            color: $jcMain; } } } } }
  @media screen and (max-width: $tablet) {
    padding: 2rem 5rem 2rem 5rem; }
  @media screen and (max-width: 970px) {
    .nav {
      .nav-list {
        display: none; }
      .mobile {
        display: block;
        .menu-icon {
          i {
            font-size: 1.5rem;
            cursor: pointer; } } } } }
  @media screen and (max-width: $phone) {
    padding: 1.5rem 3rem 1.5rem 3rem;
    .logo {
      .line, .name {
        display: none; } } } }

.mobile-nav {
  display: none;
  height: 100vh;
  width: 100%;
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(25,26,29, .98);
  backdrop-filter: blur( 17px );
  -webkit-backdrop-filter: blur( 17px );
  i {
    cursor: pointer; }
  .nav-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    text-align: center;
    align-items: center;
    list-style: none;
    margin-top: 4rem;
    font-size: 1.3rem;
    a {
      color: $jcWhite;
      text-decoration: none;
      font-weight: 500; } }
  .head {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
    i {
      font-size: 2rem;
      color: $jcRed;
      cursor: pointer; }
    h1 {
      color: $jcWhite;
      text-align: center;
      font-size: 2.5rem; } }
  p {
    text-align: center;
    position: absolute;
    bottom: 8rem;
    left: 50%;
    transform: translate(-50%);
    color: $jcWhite;
    font-weight: 100;
    font-size: 1.1rem; } }
.subtitle {
  font-family: $hand;
  font-size: 2.5rem;
  font-weight: 900;
  color: $jcMain;
  text-align: center; }

#hero {
  height: 100vh;
  width: 100%;
  margin-top: -5rem;
  position: relative;
  .subtitle {
    font-family: $hand;
    font-size: 3rem;
    font-weight: 900;
    color: $jcMain;
    text-align: center; }
  h1 {
    margin-top: -.5rem;
    text-align: center;
    font-size: 4rem;
    font-weight: 900; }
  .desc {
    position: relative;
    margin: 4rem auto 0 auto;
    width: 55rem;
    background-color: $jcBlack-main;
    color: $jcWhite;
    border-radius: 1.5rem;
    padding: 3rem;
    .title-bar {
      display: flex;
      gap: 2rem;
      align-items: center;
      margin-bottom: 2rem;
      .points {
        display: flex;
        gap: .6rem;
        .red, .yellow, .green {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: $jcWhite; }
        .red {
          background-color: #FF6354; }
        .yellow {
          background-color: #FFCF03; }
        .green {
          background-color: #12E139; } } }
    .text {
      line-height: 2.4;
      font-family: $code;
      .grey {
        font-family: $code;
        font-weight: 900;
        color: #646B77; }
      .green {
        font-family: $code;
        color: $jcGreen; } } }
  .first {
    margin-top: 0;
    font-family: $code; }
  .github-link {
    width: 16rem;
    position: relative;
    margin: .8rem auto 0 auto;
    a {
      text-align: center;
      display: flex;
      align-items: center;
      gap: .5rem;
      text-decoration: none;
      transition: 100ms;
      color: $jcBlack-main;
      font-weight: 600;
      &:hover {
        color: $jcMain; }
      i {
        font-size: 1.5rem; } } }
  .arrow-button {
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translate(-50%);
    text-decoration: none;
    transition: 100ms;
    i {
      font-size: 1.8rem;
      color: $jcBlack-main; } }
  @media screen and (min-width: $desktopWQHD) {
    padding-top: 18rem;
    .arrow-button {
      bottom: 14rem; }
    .desc {
      width: 57rem; } }
  @media screen and (max-width: $tablet) {
    padding-top: 14rem;
    h1 {
      padding: 0 2rem;
      margin-top: -.5rem; }
    .desc {
      margin-top: 1.5rem; }
    .arrow-button {
      bottom: 3rem; } }

  @media screen and (max-width: 1920px) {
    padding-top: 12rem;
    .subtitle {
      margin-top: 2rem; }
    h1 {
      font-size: 3.3rem; }
    .desc {
      margin-top: 3rem;
      padding: 2rem; } }

  @media screen and (max-width: 780px) {
    padding-top: 14rem;
    .subtitle {
      margin-top: 0; }
    .desc {
      width: 85%;
      padding: 1.4rem;
      margin-top: 4rem;
      .text {
        .grey, .green {
          display: none; } } } }
  @media screen and (min-height: 1080px) {
    padding-top: 20rem;
    height: 100vh; }
  @media screen and (max-width: 950px) {
    padding-top: 16rem; }
  @media screen and (max-height: 900px) {
    height: 102vh; }
  @media screen and (max-height: 840px) {
    height: 110vh; }
  @media screen and (max-height: 780px) {
    height: 115vh; }
  @media screen and (max-height: 740px) {
    height: 120vh;

    h1 {
      line-height: .9;
      margin-top: 0;
      font-size: 3rem; }
    h2 {
      font-size: 1.4rem; } }
  @media screen and (max-height: 900px) {
    .arrow-button {
      display: none; } }
  @media screen and (max-width: 950px) {
    height: 115vh;
    .subtitle {
      margin-top: -2rem;
      font-size: 2.5rem; }
    h1 {
      font-size: 3rem;
      margin-top: 0; }
    .desc {
      width: 85%;
      padding: 1.4rem;
      margin-top: 2rem; } } }
#projects {
  margin-top: 5rem;
  min-height: 100vh;
  background-color: $jcGray;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  hr {
    width: 40%;
    border: 1px solid #d3d4d9; }
  .title {
    text-align: center;
    font-weight: 900;
    font-size: 2.6rem;
    margin-bottom: 1rem;
    margin-top: -.5rem; }
  .project {
    display: flex;
    gap: 10rem;
    margin: 4rem;
    align-items: center;
    img {
      width: 30rem;
      border-radius: 1rem; }
    .text {
      width: 30rem;
      h1 {
        font-size: 2.2rem;
        font-weight: 700; }
      .desc {
        font-weight: 100;
        font-size: 1.1rem;
        line-height: 1.3;
        margin-bottom: 1rem;
        font-weight: 500;
        padding-bottom: 1rem; }
      a {
        color: $jcMain;
        text-decoration: none;
        font-weight: bold; } } }
  .frameworks {
    display: flex;
    gap: .5rem;
    margin-bottom: 2rem;
    margin-top: .6rem;
    .tag {
      background-color: $jcBlack-main;
      color: $jcWhite;
      border-radius: 10rem;
      position: relative;
      padding: .4rem .7rem;
      font-size: .9rem;
      transition: 100ms;
      &:hover {
        background-color: $jcMain;
        cursor: default; }
      p {
        font-family: $code; } } }
  .typescript {
    h1 {
      width: 40rem; } }
  @media screen and (max-width: $desktopWQHD) {
    overflow: hidden;
    max-width: 100vw;
    .project {
      gap: 12rem !important; } }
  @media screen and (max-width: 1200px) {
    .project {
      gap: 5rem !important; } }
  @media screen and (max-width: 1200px) {
    .project {
      margin-top: 2rem;
      flex-direction: column-reverse;
      img {
        margin-top: -4rem;
        margin-bottom: 1rem; } } }
  @media screen and (max-width: $tablet) {
    margin-top: 5rem; }
  @media screen and (max-width: 950px) {
    .title {
      padding-left: 1rem;
      padding-right: 1rem; } }
  @media screen and (max-width: 560px) {
    .text {
      text-align: center;
      h1, .desc {
        width: 80vw !important;
        position: relative !important;
        margin: 0 auto !important;
        padding: 0; } }
    .frameworks {
      justify-content: center; } }

  @media screen and (max-width: 520px) {
    .project {
      img {
        border-radius: 0 !important;
        width: 100vw !important; } } } }

#skills {
  min-height: 60vh;
  padding-top: 6rem;
  h1 {
    text-align: center;
    font-weight: 900;
    font-size: 2.6rem;
    margin-top: -.5rem; }
  .list {
    display: grid;
    grid-template-columns: 20rem 20rem 20rem;
    justify-content: center;
    margin-top: 4rem;
    column-gap: 5%;
    font-weight: 100;
    line-height: 2;
    .part {
      p {
        margin-top: 1rem;
        line-height: 1.5;
        font-weight: 500; } } }
  @media screen and (max-width: $tablet) {
    min-height: 50vh;
    .list {
      grid-template-columns: 20rem 20rem 20rem;
      column-gap: 5rem; } }
  @media screen and (max-width: 1230px) {
    min-height: 70vh;
    .list {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 2.5rem;
      padding: 0 10%; } }
  @media screen and (max-width: 580px) {
    min-height: 90vh; } }

#about {
  padding-top: 6rem;
  background-color: $jcGray;
  margin-top: 3rem;
  padding-bottom: 6rem;
  .subtitle {
    margin-top: 0; }
  h1 {
    font-size: 2.6rem;
    font-weight: 900;
    text-align: center;
    margin-bottom: 4rem;
    margin-top: -.25rem; }
  img {
    width: 20rem;
    clip-path: circle(10rem at 10rem 10rem);
    position: absolute;
    left: 50%;
    transform: translate(-50%); }
  p {
    width: 30rem;
    text-align: center;
    position: relative;
    margin: 28rem auto 0 auto;
    line-height: 1.5; }
  @media screen and (max-width: 700px) {
    p {
      width: 80%; } } }

footer {
  display: flex;
  justify-content: space-between;
  padding: 2rem 10rem 2rem 10rem;
  align-items: center;
  p {
    font-weight: bold; }
  a {
    color: $jcMain;
    font-weight: bold; }
  @media screen and (max-width: $tablet) {
    padding: 2rem; } }
